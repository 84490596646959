import React, { useState } from 'react'
import Icon from './Icon'
import ImageWrapper from './ImageWrapper'
import uk from '../img/ukbg.jpg'
import de from '../img/debg.webp'
import fr from '../img/frbg.png'
import useComponentVisible from '../utils/useComponentVisible'

function LanguagePicker({onChange, className, ...props}) {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    
  return (
    <div ref={ref} className={`relative ${className}`} {...props}>
        <Icon onClick={() => setIsComponentVisible(!isComponentVisible)} name="planet" className="cursor-pointer w-6 h-6" />
        <div className={`${isComponentVisible?"h-auto":"max-h-0"} z-[999] overflow-hidden transition-all duration-300 absolute top-[125%] right-0 bg-white shadow-benefits rounded-lg flex flex-col items-start`}>
            <li onClick={() => {
                    setIsComponentVisible(false)
                    onChange('en')
                }} className='bg-white cursor-pointer w-full transition-all duration-100 hover:bg-[#f1f1f1] p-4 px-6 text-sm text-heading rounded-[inherit] flex items-center gap-2'>
                <ImageWrapper src={uk} object="cover" className="w-6 h-4 rounded-sm" alt="UK"/>
                English
            </li>
            <li onClick={() => {
                    setIsComponentVisible(false)
                    onChange('de')
                }} className='bg-white cursor-pointer w-full transition-all duration-100 hover:bg-[#f1f1f1] p-4 px-6 text-sm text-heading rounded-[inherit] flex items-center gap-2'>
                <ImageWrapper src={de} object="cover" className="w-6 h-4 rounded-sm" alt="UK"/>
                Deutsch
            </li>
            <li onClick={() => {
                    setIsComponentVisible(false)
                    onChange('fr')
                }} className='bg-white cursor-pointer w-full transition-all duration-100 hover:bg-[#f1f1f1] p-4 px-6 text-sm text-heading rounded-[inherit] flex items-center gap-2'>
                <ImageWrapper src={fr} object="cover" className="w-6 h-4 rounded-sm" alt="UK"/>
                Français
            </li>
        </div>
    </div>
  )
}

export default LanguagePicker