import Icon from './Icon';

function Button({ children, icon=true, href, additionalStyles, ...props }) {
    return (
        <a
            className={`group cursor-pointer text-base font-semibold flex items-center gap-2 py-3 ${icon?'pl-10 pr-8':'px-8'} bg-brand rounded-md ${additionalStyles}`}
            href={href}
            {...props}>
            {children}
            {icon ? (
                <Icon
                    name="arrow"
                    className="group-hover:w-4 group-hover:h-4 transition-all w-0 h-0"
                />
            ) : null}
        </a>
    );
}

export default Button;
