import React from 'react';

function PrivacyPolicy() {
    return (
        <div className="page__width text-sm text-text my-20">
            <h1 className='text-brand text-4xl mb-4 font-bold'>Privacy Policy</h1>
            Smooth Affairs Limited ("us", "we", or "our") operates the
            http://www.smoothaffairs.co.uk website (the "Service"). This page
            informs you of our policies regarding the collection, use, and
            disclosure of personal data when you use our service and the choices
            you have associated with that data. Unless otherwise defined in this
            privacy policy, terms used in this privacy policy have the same
            meanings as in our terms and conditions, accessible from
            http://www.smoothaffairs.co.uk
            <h2 className="text-2xl mt-2 text-white">Definitions</h2>
            <h2 className="text-xl text-white">Personal data</h2>
            Personal data means any information relating to an identified or
            identifiable natural person ('data subject'); an identifiable
            natural person is one who can be identified, directly or indirectly,
            in particular by reference to an identifier such as a name, an
            identification number, location data, an online identifier or to one
            or more factors specific to the physical, physiological, genetic,
            mental, economic, cultural or social identity of that natural.
            <h2 className="text-2xl mt-2 text-white">Usage data</h2>
            Usage data is data collected automatically either generated by the
            use of the service or from the service infrastructure itself (for
            example, the duration of a page visit).
            <h2 className="text-2xl mt-2 text-white">Cookies</h2>
            Cookies are small pieces of data stored on a user's device.
            <h2 className="text-2xl mt-2 text-white">Data controller</h2>
            Data controller means a person who (either alone or jointly or in
            common with other persons) determines the purposes for which and the
            manner in which any personal data are, or are to be, processed. For
            the purpose of this privacy policy, we are a data controller of your
            data. Data processor (or service providers) Data processor (or
            service provider) means any person (other than an employee of the
            data controller) who processes the data on behalf of the data
            controller. We may use the services of various service providers in
            order to process your data more effectively.
            <h2 className="text-2xl mt-2 text-white">Data subject</h2>
            Data subject is any natural person who is the subject of personal
            data.
            <h2 className="text-2xl mt-2 text-white">User</h2>
            The user is the individual using our service. The user corresponds
            to the data subject, who is the subject of personal data.
            Information collection and use We collect several different types of
            information for various purposes to provide and improve our service
            to you.
            <h2 className="text-2xl mt-2 text-white">User consent</h2>
            Personal and usage data are collected from you after your consent
            (opt-in) has been obtained by ticking the appropriate box when
            requested to do so. Types of data collected
            <h2 className="text-2xl mt-2 text-white">Personal data</h2>
            While using our service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you ("personal data"). Personally identifiable information
            may include, but is not limited to: Email address First name and
            last name Cookies and usage data We may use your personal data to
            contact you with newsletters, marketing or promotional materials and
            other information that may be of interest to you. You may opt out of
            receiving any, or all, of these communications from us by following
            the unsubscribe link or instructions provided in any email we send.
            <h2 className="text-2xl mt-2 text-white">Usage data</h2>
            We may also collect information how the service is accessed and used
            ("usage data"). This usage data may include information such as your
            computer's internet protocol address (e.g. IP address), browser
            type, browser version, the pages of our service that you visit, the
            time and date of your visit, the time spent on those pages, unique
            device identifiers and other diagnostic data.
            <h2 className="text-2xl mt-2 text-white">
                Tracking & cookies data
            </h2>
            Our website uses cookies to distinguish you from other users of our
            website. This helps us to provide you with a good experience when
            you browse our website and also allows us to improve our site. By
            continuing to browse the site, you are agreeing to our use of
            cookies. A cookie is a small file of letters and numbers that we
            store on your browser or the hard drive of your computer if you
            agree. Cookies contain information that is transferred to your
            computer’s hard drive.
            <h2 className="text-2xl mt-2 text-white">
                We may use the following cookies:
            </h2>
            Strictly necessary cookies. These are cookies that are required for
            the operation of our website. They include, for example, cookies
            that enable you to log into secure areas of our website, use a
            shopping cart or make use of e-billing services.
            <h2 className="text-2xl mt-2 text-white">
                Analytical/performance cookies
            </h2>
            These allow us to recognise and count the number of visitors and to
            see how visitors move around our website when they are using it.
            This helps us to improve the way our website works, for example, by
            ensuring that users are finding what they are looking for easily.
            Please note that third parties (including, for example, advertising
            networks and providers of external services like web traffic
            analysis services) may also use cookies, over which we have no
            control. These cookies are likely to be analytical/performance
            cookies or targeting cookies. You block cookies by activating the
            setting on your browser that allows you to refuse the setting of all
            or some cookies. However, if you use your browser settings to block
            all cookies (including essential cookies) you may not be able to
            access all or parts of our site.
            <h2 className="text-2xl mt-2 text-white">Use of data</h2>
            Smooth Affairs Limited uses the collected data for various purposes:
            To provide and maintain our service To notify you about changes to
            our service To allow you to participate in interactive features of
            our service when you choose to do so To provide customer support To
            gather analysis or valuable information so that we can improve our
            service To monitor the usage of our service To detect, prevent and
            address technical issues To provide you with news, special offers
            and general information about other goods, services and events which
            we offer that are similar to those that you have already purchased
            or enquired about unless you have opted not to receive such
            information
            <h2 className="text-2xl mt-2 text-white">Retention of data</h2>
            Smooth Affairs Limited will retain your personal data only for as
            long as is necessary for the purposes set out in this privacy
            policy. We will retain and use your personal data to the extent
            necessary to comply with our legal obligations (for example, if we
            are required to retain your data to comply with applicable laws),
            resolve disputes, and enforce our legal agreements and policies.
            Smooth Affairs Limited will also retain usage data for internal
            analysis purposes. Usage data is generally retained for a shorter
            period of time, except when this data is used to strengthen the
            security or to improve the functionality of our service, or we are
            legally obligated to retain this data for longer time periods.
            <h2 className="text-2xl mt-2 text-white">Transfer of data</h2>
            Your information, including personal data, may be transferred to —
            and maintained on — computers located outside of your state,
            province, country or other governmental jurisdiction where the data
            protection laws may differ than those from your jurisdiction. If you
            are located outside Monaco and choose to provide information to us,
            please note that we transfer the data, including personal data, to
            Monaco and process it there. Your consent to this privacy policy
            followed by your submission of such information represents your
            agreement to that transfer. Smooth Affairs Limited will take all
            steps reasonably necessary to ensure that your data is treated
            securely and in accordance with this privacy policy and no transfer
            of your personal data will take place to an organisation or a
            country unless there are adequate controls in place including the
            security of your data and other personal information.
            <h2 className="text-2xl mt-2 text-white"> Disclosure of data</h2>
            <h2 className="text-2xl mt-2 text-white">
                {' '}
                Disclosure for law enforcement
            </h2>
            Under certain circumstances, Smooth Affairs Limited may be required
            to disclose your personal data if required to do so by law or in
            response to valid requests by public authorities (e.g. a court or a
            government agency). Legal requirements Smooth Affairs Limited may
            disclose your personal data in the good faith belief that such
            action is necessary to: To comply with a legal obligation To protect
            and defend the rights or property of Smooth Affairs Limited To
            prevent or investigate possible wrongdoing in connection with the
            service To protect the personal safety of users of the service or
            the public To protect against legal liability
            <h2 className="text-2xl mt-2 text-white"> Security of data</h2>
            The security of your data is important to us but remember that no
            method of transmission over the Internet, or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your personal data, we cannot guarantee
            its absolute security.
            <h2 className="text-2xl mt-2 text-white"> Your rights</h2>
            Pursuant to modified Law No. 1,165 of 23 December 1993, you may
            contact us at the following address in order to: Access and receive
            a copy of the personal data we hold about you. Rectify any personal
            data held about you that is inaccurate. Request the deletion of
            personal data held about you.
            <br />
            Smooth Affairs Limited 25 Thurso House Randolph Gardens, NW65EL
            mariyan.alekov@smoothaffairs.co.uk Whenever made possible, you can
            also update your personal data directly within your account settings
            section. If you are unable to change your personal data, please
            contact us to make the required changes. If you wish to be informed
            what personal data, we hold about you and if you want it to be
            removed from our systems, please contact us. You have the right to
            data portability for the information you provide to Smooth Affairs
            Limited. You can request to obtain a copy of your personal data in a
            commonly used electronic format so that you can manage and move it.
            Please note that we may ask you to verify your identity before
            responding to such requests.
            <h2 className="text-2xl mt-2 text-white"> Service providers</h2>
            We may employ third party companies and individuals to facilitate
            our service ("service providers"), to provide the service on our
            behalf, to perform service-related services or to assist us in
            analysing how our service is used. These third parties have access
            to your personal data only to perform these tasks on our behalf and
            are obligated not to disclose or use it for any other purpose.
            Analytics We may use third-party service providers to monitor and
            analyse the use of our service. * Google Analytics Google analytics
            is a web analytics service offered by Google that tracks and reports
            website traffic. Google uses the data collected to track and monitor
            the use of our service. This data is shared with other Google
            services. Google may use the collected data to contextualise and
            personalise the ads of its own advertising network. You can opt-out
            of having made your activity on the service available to Google
            analytics by installing the Google analytics opt-out browser add-on.
            The add-on prevents the Google analytics JavaScript (ga.js,
            analytics.js, and dc.js) from sharing information with Google
            analytics about visits activity. For more information on the privacy
            practices of Google, please visit the Google privacy & terms web
            page: http://www.google.com/intl/en/policies/privacy/
            <h2 className="text-2xl mt-2 text-white"> Links to other sites</h2>
            Our service may contain links to other sites that are not operated
            by us. If you click on a third party link, you will be directed to
            that third party's site. We strongly advise you to review the
            privacy policy of every site you visit. We have no control over and
            assume no responsibility for the content, privacy policies or
            practices of any third party sites or services.
            <h2 className="text-2xl mt-2 text-white"> Children's privacy</h2>
            Our service does not address anyone under the age of 16
            ("Children"). We do not knowingly collect personally identifiable
            information from anyone under the age of 16. If you are a parent or
            guardian and you are aware that your children has provided us with
            personal data, please contact us. If we become aware that we have
            collected personal data from children without verification of
            parental consent, we take steps to remove that information from our
            servers.
            <h2 className="text-2xl mt-2 text-white">
                {' '}
                <h2 className="text-2xl mt-2 text-white">Use of data</h2>
            </h2>
            We may update our privacy policy from time to time. We will notify
            you of any changes by posting the new privacy policy on this page.
            We will let you know via email and/or a prominent notice on our
            service, prior to the change becoming effective and update the
            "effective date" at the top of this privacy policy. You are advised
            to review this privacy policy periodically for any changes. Changes
            to this privacy policy are effective when they are posted on this
            page.
            <h2 className="text-2xl mt-2 text-white"> Contact us</h2>
            If you have any questions about this privacy policy, please contact
            us: * By email: mariyan.alekov@smoothaffairs.co.uk
        </div>
    );
}

export default PrivacyPolicy;
