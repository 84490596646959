import './index.css'
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import AnimationLayout from './AnimationLayout'
import Home from "./routes/Home";
import Lenis from 'lenis';
import Services from './routes/Services';
import Contact from './routes/Contact';
import NotFound from './routes/NotFound';
import PrivacyPolicy from './routes/PrivacyPolicy';
import TermsAndConditions from './routes/TermsAndConditions';

function App() {
  useEffect(() => {
    const lenis = new Lenis({
      duration: 1, // speed
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
      direction: "vertical", // vertical, horizontal
      gestureDirection: "vertical", // vertical, horizontal, both
      smooth: true,
      mouseMultiplier: 0.5, // sensibility
      smoothTouch: false, // Mobile
      touchMultiplier: 2, // sensibility on mobile
      infinite: false // Infinite scrolling
    });
    
      function raf(time) {
          lenis.raf(time)
          requestAnimationFrame(raf)
      }
      requestAnimationFrame(raf)
  }, [])

  return (
    <Routes>
      <Route element={<AnimationLayout />}>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsAndConditions />} />
        <Route path="/*" element={<NotFound />} />
        </Route>
    </Routes>
  );
}

export default App;
