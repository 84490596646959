import { Field } from "formik"

function Input({ type, name, placeholder, component, className }) {
    return (
        <Field
            type={type}
            name={name}
            component={component}
            placeholder={placeholder}
            autoComplete="off"
            className={"inline-block py-3 text-[#d4d4d4] px-4 w-full outline-none border-2 border-[#d4d4d4] rounded-md my-1 " + className} />
    )
}

export default Input