import Icon from '../components/Icon';
import ImageWrapper from '../components/ImageWrapper';
import muller from '../img/muller.jpg';
import '../index.css';
import useEmblaCarousel from 'embla-carousel-react';
import { useCallback, useEffect } from 'react';
import { DotButton, useDotButton } from './EmblaCarouselDotButton';
import { useTranslation } from 'react-i18next';

function Testimonials() {
    const { t } = useTranslation();

    const [emblaRef, emblaApi] = useEmblaCarousel({
        loop: true,
        watchDrag: true,
    });

    const { selectedIndex, scrollSnaps, onDotButtonClick } =
        useDotButton(emblaApi);
    useEffect(() => {
        if (emblaApi) {
            console.log(emblaApi.slideNodes()); // Access API
        }
    }, [emblaApi]);
    const scrollPrev = useCallback(() => {
        if (emblaApi) emblaApi.scrollPrev();
    }, [emblaApi]);

    const scrollNext = useCallback(() => {
        if (emblaApi) emblaApi.scrollNext();
    }, [emblaApi]);
    return (
        <div
            className="embla bg-testimonials bg-cover py-20 flex flex-col items-center justify-center"
            ref={emblaRef}>
            <div className="embla__container flex items-center gap-8 page_width">
                <div className="flex flex-col items-center embla__slide w-full">
                    <h2 className="w-2/3 lg:w-1/2 text-2xl lg:text-3xl text-center text-white">
                        {t('home-testimonials-1-comment')}
                    </h2>
                    {/* <div className="flex items-center mt-8 gap-2">
                        <ImageWrapper
                            src={muller}
                            className="rounded-full w-16 h-16"
                        />
                        <div className="flex flex-col items-start">
                            <h5 className="text-lg font-bold">
                                {t('home-testimonials-1-name')}
                            </h5>
                            <p className="text-heading uppercase text-sm">
                                {t('home-testimonials-1-region')}
                            </p>
                        </div>
                    </div> */}
                </div>
                <div className="flex flex-col items-center embla__slide w-full">
                    <h2 className="w-2/3 lg:w-1/2 text-2xl lg:text-3xl text-center text-white">
                        {t('home-testimonials-2-comment')}
                    </h2>
                    {/* <div className="flex items-center mt-8 gap-2">
                        <ImageWrapper
                            src={muller}
                            className="rounded-full w-16 h-16"
                        />
                        <div className="flex flex-col items-start">
                            <h5 className="text-lg font-bold">
                                {t('home-testimonials-2-name')}
                            </h5>
                            <p className="text-heading uppercase text-sm">
                                {t('home-testimonials-2-region')}
                            </p>
                        </div>
                    </div> */}±
                </div>
                <div className="flex flex-col items-center embla__slide w-full">
                    <h2 className="w-2/3 lg:w-1/2 text-2xl lg:text-3xl text-center text-white">
                        {t('home-testimonials-3-comment')}
                    </h2>
                    {/* <div className="flex items-center mt-8 gap-2">
                        <ImageWrapper
                            src={muller}
                            className="rounded-full w-16 h-16"
                        />
                        <div className="flex flex-col items-start">
                            <h5 className="text-lg font-bold">
                                {t('home-testimonials-3-name')}
                            </h5>
                            <p className="text-heading uppercase text-sm">
                                {t('home-testimonials-3-region')}
                            </p>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="embla__dots">
                {scrollSnaps.map((_, index) => (
                    <DotButton
                        key={index}
                        onClick={() => onDotButtonClick(index)}
                        className={'embla__dot'.concat(
                            index === selectedIndex
                                ? ' embla__dot--selected'
                                : ''
                        )}
                    />
                ))}
            </div>
            <button
                className="embla__prev absolute hidden lg:block left-40 top-0 bottom-0"
                onClick={scrollPrev}>
                <Icon
                    name="arrow"
                    className="w-8 h-8 text-white rotate-180"
                />
            </button>
            <button
                className="embla__next absolute hidden lg:block right-40 top-0 bottom-0"
                onClick={scrollNext}>
                <Icon
                    name="arrow"
                    className="w-8 h-8 text-white"
                />
            </button>
        </div>
    );
}

export default Testimonials;
