import ImageWrapper from './ImageWrapper';
import {footer} from '../utils/menus'
import logo from '../img/cp-logo-cmyk.png'
import { useTranslation } from 'react-i18next';

function Footer() {
    const {t} = useTranslation();
    return (
        <>
        <div className="bg-footer bg-cover w-full flex flex-col items-end pt-64 pb-24">
            <div className='page__width flex flex-col lg:flex-row items-center lg:items-start justify-between'>
                <ImageWrapper src={logo} className="w-80" />
                {Object.keys(footer).map((key) => {
                        return (
                            <div
                                key={key}
                                className="w-full lg:w-auto flex flex-col items-center text-center lg:text-start lg:items-start">
                                <h3 className="text-heading text-2xl font-bold mb-2">
                                    {key}
                                </h3>
                                <ul>
                                    {footer[key].map((link, i) => {
                                        return (
                                            <li
                                                key={i}
                                                className="my-2 text-text text-base">
                                                <a
                                                    key={i}
                                                    className="hover:underline"
                                                    href={link.href}>
                                                    {t(link.label)}
                                                </a>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        );
                    })}
                    <div className="w-full lg:w-auto flex flex-col items-center text-center lg:text-start lg:items-start">
                    <h3 className="text-heading text-2xl font-bold mb-2">Call Us</h3>
                    <a href="tel:+359 876713508" className="text-text text-base">+359 876713508</a>
                    <h3 className="text-heading text-2xl my-2 font-bold">Or Send an Email</h3>
                    <a href="mailto:info@connectprint.net" className="text-text text-base">info@connectprint.net</a>
                    </div>
            </div>
        </div>
        </>
    );
}

export default Footer;
