import React from 'react';

function TermsAndConditions() {
    return (
        <div className="page__width text-sm text-text my-20">
            <h1 className="text-brand text-4xl mb-4 font-bold">
                Terms & Conditions
            </h1>
            Welcome to our website. If you continue to browse and use this
            website, you are agreeing to comply with and be bound by the
            following terms and conditions of use, which together with our
            privacy policy govern Smooth Affairs Limited relationship with you
            in relation to this website. If you disagree with any part of these
            terms and conditions, please do not use our website. The term
            'Smooth Affairs Limited' or 'us' or 'we' refers to the owner of the
            website whose registered office is 25 Thurso House, Randolph Avenue,
            London, NW65EL. Our company register number is: 15788453. The term
            'you' refers to the user or viewer of our website.
            <br />
            <br />
            The use of this website is subject to the following terms of use:
            The content of the pages of this website is for your general
            information and use only. It is subject to change without notice.
            <br />
            <br />
            This website uses cookies to monitor browsing preferences. If you do
            allow cookies to be used, the following personal information may be
            stored by us for use by third parties: First party cookies which are
            our own cookies, controlled by us and are used to enable secure
            functionality for site administrators and content editors. Third
            party cookies which are found in other companies internet tools
            which we are using to enhance our site, such as Google Analytics..
            <br />
            <br />
            Neither we nor any third parties provide any warranty or guarantee
            as to the accuracy, timeliness, performance, completeness or
            suitability of the information and materials found or offered on
            this website for any particular purpose. You acknowledge that such
            information and materials may contain inaccuracies or errors and we
            expressly exclude liability for any such inaccuracies or errors to
            the fullest extent permitted by law. Your use of any information or
            materials on this website is entirely at your own risk, for which we
            shall not be liable. It shall be your own responsibility to ensure
            that any products, services or information available through this
            website meet your specific requirements. This website contains
            material which is owned by or licensed to us. This material
            includes, but is not limited to, the design, layout, look,
            appearance and graphics. Reproduction is prohibited other than in
            accordance with the copyright notice, which forms part of these
            terms and conditions. All trademarks reproduced in this website
            which are not the property of, or licensed to, the operator is
            acknowledged on the website. Unauthorised use of this website may
            give rise to a claim for damages and/or be a criminal offence. From
            time to time this website may also include links to other websites.
            These links are provided for your convenience to provide further
            information. They do not signify that we endorse the website(s). We
            have no responsibility for the content of the linked website(s).
            Your use of this website and any dispute arising out of such use of
            the website is subject to the laws of England, Northern Ireland,
            Scotland and Wales. See also our Privacy Policy
        </div>
    );
}

export default TermsAndConditions;
