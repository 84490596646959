import ImageWrapper from './ImageWrapper';
import { useState } from 'react';
import { navbar } from '../utils/menus';
import SidebarLink from '../components/SidebarLink';
import Icon from './Icon';
import Button from './Button';
import logo from '../img/cp-logo-cmyk.png';
import Cookies from 'js-cookie';
import { useTranslation } from 'react-i18next';
import LanguagePicker from './LanguagePicker';

function Navbar() {
    const [open, toggleSidebar] = useState(false);
    const [scrollY, setScrollY] = useState(window.scrollY > 100);
    window.addEventListener('scroll', () => {
        if (window.scrollY > 100) setScrollY(true);
        else setScrollY(false);
    });

    const { i18n, t } = useTranslation();

    const onChangeLang = (lang_code) => {
        // localStorage.setItem('lang', lang_code)
        console.log(i18n.language);
        Cookies.set('lang', lang_code);
        i18n.changeLanguage(lang_code);
    };

    return (
        <div
            className={`absolute top-0 ${
                open ? 'bg-white' : 'bg-transparent'
            } z-[99] w-full flex justify-between items-center py-8`}>
            <div className="page__width relative flex items-center justify-between">
                <div className="flex items-center gap-8">
                    <a href="/">
                        <ImageWrapper
                            src={logo}
                            alt="Logo"
                            className="w-56"
                        />
                    </a>
                    <div className="hidden lg:flex items-center gap-8">
                        {navbar.map((link, i) => {
                            return (
                                <>
                                    {link.submenu ? (
                                        <SidebarLink
                                            key={i}
                                            sublinks={link.submenu}
                                            scrollY={scrollY}
                                            href={link.href}>
                                            {t(link.label)}
                                        </SidebarLink>
                                    ) : (
                                        <a
                                            key={i}
                                            href={link.href}
                                            className={`animated_link relative block text-base text-body`}>
                                            {t(link.label)}
                                        </a>
                                    )}
                                </>
                            );
                        })}
                    </div>
                </div>
                <div className="flex items-center gap-4">
                    <Button
                        additionalStyles="hidden lg:flex"
                        href="/contact">
                        {t('nav-cta')}
                    </Button>
                    {/* <select
                        onChange={(event) => onChangeLang(event.target.value)}
                        className="hidden lg:block bg-none p-2 rounded-md border border-brand text-brand outline-none">
                        <option
                            disabled
                            selected
                            value>
                            {t('language')}
                        </option>

                        <option value="fr">French</option>
                        <option value="en">English</option>
                        <option value="de">German</option>
                    </select> */}
                    <LanguagePicker className="hidden lg:block" onChange={(lang) => onChangeLang(lang)} />
                    {/* <button
                        onClick={() => onChangeLang('fr')}
                        className={`bg-${i18n.language} hidden lg:block bg-cover bg-center w-8 h-8 rounded-full`}></button> */}
                </div>
                <div className="lg:hidden flex items-center">
                <LanguagePicker className="lg:hidden mr-6" onChange={(lang) => onChangeLang(lang)} />
                    <button onClick={() => toggleSidebar(!open)}>
                        {open ? (
                            <Icon
                                name="x-icon"
                                className="w-6 h-6 mr-6 text-heading"
                            />
                        ) : (
                            <Icon
                                name="burger"
                                className={`w-6 h-6 mr-6 text-heading`}
                            />
                        )}
                    </button>
                </div>
            </div>
            <div
                className={`absolute left-0 top-full duration-200 transition-all bg-white justify-between w-full z-[99] overflow-y-hidden lg:hidden ${
                    open ? 'h-auto pb-4' : 'h-0'
                }`}>
                <div className="flex flex-col items-start">
                    {navbar.map((link, i) => {
                        return (
                            <>
                                {link.submenu ? (
                                    <SidebarLink
                                        key={i}
                                        sublinks={link.submenu}
                                        href={link.href}>
                                        {t(link.label)}
                                    </SidebarLink>
                                ) : (
                                    <a
                                        key={i}
                                        href={link.href}
                                        className="px-[7.5%] block w-full text-base text-black py-4 transition-all duration-200">
                                        {t(link.label)}
                                    </a>
                                )}
                            </>
                        );
                    })}
                    <div className="page__width flex items-center justify-center">
                        <Button href="/contact">{t('nav-cta')}</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Navbar;
