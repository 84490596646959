import React from 'react';
import ImageWrapper from '../components/ImageWrapper';
import catalog from '../img/catalog.png';
import books from '../img/books.png';
import hardcover from '../img/hardcover.png';
import softcover from '../img/softcover.png';
import magazines from '../img/magazines.png';
import Icon from '../components/Icon';
import Button from '../components/Button';
import ContactForm from '../components/ContactForm';
import { useTranslation } from 'react-i18next';

export default function Services() {
    const {t} = useTranslation()
    return (
        <>
            <div className="flex flex-col items-center page__width gap-6 mt-40 lg:mb-24">
                <h1 className="text-4xl lg:text-6xl font-extrabold text-heading text-center">
                    {t('services-headline')}
                </h1>
            </div>
            <div className="page__width flex my-8 flex-col-reverse gap-8 lg:gap-0 lg:flex-row items-center justify-center">
                <div className="w-full lg:w-1/2 pr-8 flex items-start flex-col gap-2">
                    <h2 className="text-4xl font-extrabold text-heading">
                        {t('services-1-headline')}
                    </h2>
                    <p className="text-lg text-text">{t('services-1-subheadline')}
                    </p>
                   <Button href="/contact">{t('services-1-cta')}</Button>
                </div>
                <ImageWrapper
                    className="w-full lg:w-1/3"
                    src={books}
                />
            </div>
            <div className="page__width flex my-8 flex-col-reverse lg:flex-row-reverse gap-16 items-center justify-center">
                <div className="w-full lg:w-1/2 pr-8 flex items-start flex-col gap-2">
                    <h2 className="text-4xl font-extrabold text-heading">
                        {t('services-2-headline')}
                    </h2>
                    <p className="text-lg text-text">{t('services-2-subheadline')}
                    </p>
                   <Button href="/contact">{t('services-2-cta')}</Button>
                </div>
                <ImageWrapper
                    className="w-full lg:w-1/3"
                    src={softcover}
                />
            </div>
            <div className="page__width flex my-8 flex-col-reverse gap-16 lg:flex-row items-center justify-center">
                <div className="w-full lg:w-1/2 pr-8 flex items-start flex-col gap-2">
                    <h2 className="text-4xl font-extrabold text-heading">
                        {t('services-3-headline')}
                    </h2>
                    <p className="text-lg text-text">{t('services-3-subheadline')}
                    </p>
                   <Button href="/contact">{t('services-3-cta')}</Button>
                </div>
                <ImageWrapper
                    className="w-full lg:w-1/3"
                    src={hardcover}
                />
            </div>
            <div className="page__width flex my-8 flex-col-reverse gap-16 lg:flex-row-reverse items-center justify-center">
                <div className="w-full lg:w-1/2 pr-8 flex items-start flex-col gap-2">
                    <h2 className="text-4xl font-extrabold text-heading">
                        {t('services-4-headline')}
                    </h2>
                    <p className="text-lg text-text">{t('services-4-subheadline')}
                    </p>
                   <Button href="/contact">{t('services-4-cta')}</Button>
                </div>
                <ImageWrapper
                    className="w-full lg:w-1/3"
                    src={magazines}
                />
            </div>
            <div className="page__width flex my-8 flex-col-reverse gap-16 lg:flex-row items-center justify-center">
                <div className="w-full lg:w-1/2 pr-8 flex items-start flex-col gap-2">
                    <h2 className="text-4xl font-extrabold text-heading">
                        {t('services-5-headline')}
                    </h2>
                    <p className="text-lg text-text">{t('services-5-subheadline')}
                    </p>
                   <Button href="/contact">{t('services-5-cta')}</Button>
                </div>
                <ImageWrapper
                    className="w-full lg:w-1/3"
                    src={catalog}
                />
            </div>
            <div className="page__width flex flex-col lg:flex-row gap-8 lg:gap-0 items-start justify-between my-40">
                <div className="w-full lg:w-2/5 flex flex-col items-start gap-2 pr-8">
                <h3 className="text-xl lg:text-2xl font-bold text-heading">{t('contactform-headline')}</h3>
                <p className="w-full text-text text-base">{t('contactform-subheadline')}</p>
                    <div className="items-center gap-4 flex">
                        <Icon
                            name="email"
                            className="w-16 h-16 m-0"
                        />
                        <a href="mailto:info@connectprint.net" className="text-base font-bold text-heading uppercase">
                            info@connectprint.net
                        </a>
                    </div>
                    <div className="items-center gap-4 flex">
                        <Icon
                            name="phone"
                            className="w-16 h-16 m-0"
                        />
                        <a href="tel:+359 876713508" className="text-base font-bold text-heading uppercase">
                            +359 876713508
                        </a>
                    </div>
                </div>
                <div className="w-full lg:w-3/5 flex flex-col items-start">
                    <ContactForm />
                </div>
            </div>
        </>
    );
}
