import React from 'react';
import ContactForm from '../components/ContactForm';
import Icon from '../components/Icon';
import { useTranslation } from 'react-i18next';

function Contact() {
    const {t} = useTranslation()
    // const accordionItems = [
    //     {
    //         id: 'Q1',
    //         title: 'What kind of events do you organize?',
    //         description:
    //             'We specialize in weddings, social gatherings, and corporate events. We take care of all the organization, catering, location, guests and everything in between so you can have a memorable day!',
    //     },
    //     {
    //         id: 'Q2',
    //         title: 'Can I change the date of my booking??',
    //         description:
    //             'We specialize in weddings, social gatherings, and corporate events. We take care of all the organization, catering, location, guests and everything in between so you can have a memorable day!',
    //     },
    //     {
    //         id: 'Q3',
    //         title: 'Can I cancel my booking?',
    //         description:
    //             'We specialize in weddings, social gatherings, and corporate events. We take care of all the organization, catering, location, guests and everything in between so you can have a memorable day!',
    //     },
    // ];

    return (
        <>
        <div className="page__width flex flex-col lg:flex-row gap-8 lg:gap-0 items-start justify-between lg:mt-64 my-40">
                <div className="w-full lg:w-2/5 flex flex-col items-start gap-2 pr-8">
                    <h3 className="text-xl lg:text-2xl font-bold text-heading">{t('contactform-headline')}</h3>
                    <p className="w-full text-text text-base">{t('contactform-subheadline')}</p>
                    <div className="items-center gap-4 flex">
                        <Icon
                            name="email"
                            className="w-16 h-16 m-0"
                        />
                        <a href="mailto:info@connectprint.net" className="text-base font-bold text-heading uppercase">
                            info@connectprint.net
                        </a>
                    </div>
                    <div className="items-center gap-4 flex">
                        <Icon
                            name="phone"
                            className="w-16 h-16 m-0"
                        />
                        <a href="tel:+359 876713508" className="text-base font-bold text-heading uppercase">
                        +359 876713508
                        </a>
                    </div>
                </div>
                <div className="w-full lg:w-3/5 flex flex-col items-start">
                    <ContactForm formName="bap"/>
                </div>
            </div>
        </>
    );
}

export default Contact;
