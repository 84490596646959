import i18next from "i18next"

const navbar = [
    {
        href: '/',
        label: "nav-home",
    },
    {
        href: '/services',
        label: "nav-services",
    },
    {
        href: '/contact',
        label: "nav-contact",
    },
];

const footer = {
    Sitemap: [
        {
            href: '/',
            label: "nav-home",
        },
        {
            href: '/services',
            label: "nav-services",
        },
    ],
    Help: [
        {
            href: '/contact',
            label: "nav-contact",
        },
        {
            href: '/privacy-policy',
            label: 'nav-pp',
        },
        {
            href: '/terms',
            label: 'nav-tnc',
        },
    ],
};
export { navbar, footer };
