import Button from '../components/Button';
import ImageWrapper from '../components/ImageWrapper';
import logo from '../img/cp-logo-cmyk.png';
import cataglogs from '../img/catalog-sq.jpg';
import books from '../img/books-sq.jpg';
import magazines from '../img/magazines.jpg';
import about from '../img/about.png';
import Testimonials from '../components/Testimonials';
import Icon from '../components/Icon';
import ContactForm from '../components/ContactForm';
import '../index.css';
import { useTranslation } from 'react-i18next';

function Home() {
    const { t } = useTranslation();
    return (
        <>
            <div className="bg-paper bg-cover w-full py-40">
                <div className="flex flex-col items-center page__width gap-6 my-20 lg:my-40">
                    <h1 className="text-5xl lg:text-7xl font-extrabold text-heading text-center">
                        {t('home-hero-headline')} <br />
                        <span className="mt-4 block text-brand">
                            {t('home-hero-headline-span')}
                        </span>
                    </h1>
                    <p className="text-lg text-text text-center lg:w-1/2">
                        {t('home-hero-subheadline')}
                    </p>
                    <div className="flex flex-col lg:flex-row items-center gap-4">
                        <Button
                            href="/contact"
                            additionalStyles="border-2 border-brand">
                            {t('home-hero-cta-primary')}
                        </Button>
                        <Button
                            href="/services"
                            icon={false}
                            additionalStyles="bg-white border-2 transition-all border-brand text-brand hover:bg-brand hover:text-white">
                            {t('home-hero-cta-secondary')}
                        </Button>
                    </div>
                </div>
            </div>
            <div className="page__width my-20 flex flex-col items-center">
                <div className="flex flex-col items-center">
                    <div className="flex gap-2 items-center">
                        <h2 className="text-4xl font-extrabold text-heading">
                            {t('home-services-headline-1')}
                        </h2>
                        <ImageWrapper
                            src={logo}
                            className="w-64"
                        />
                    </div>
                    <h2 className="text-4xl text-center font-extrabold text-heading">
                        {t('home-services-headline-2')}{' '}
                        <span className="text-brand">
                            {t('home-services-headline-span')}
                        </span>
                    </h2>
                </div>
                <div className="flex flex-col lg:flex-row items-center mt-12 gap-4 lg:gap-16">
                    <div className="flex w-10/12 lg:w-80 flex-col items-center gap-4">
                        <ImageWrapper
                            className="w-full h-full rounded-md"
                            src={cataglogs}
                        />
                        <h3 className="text-xl font-bold text-heading uppercase">
                        {t('home-services-service-1')}
                        </h3>
                    </div>
                    <div className="flex w-10/12 lg:w-80 flex-col items-center gap-4">
                        <ImageWrapper
                            className="w-full h-full rounded-md"
                            src={books}
                        />
                        <h3 className="text-xl font-bold text-heading uppercase">
                        {t('home-services-service-2')}
                        </h3>
                    </div>
                    <div className="flex w-10/12 lg:w-80 flex-col items-center gap-4">
                        <ImageWrapper
                            className="w-full h-full rounded-md"
                            src={magazines}
                        />
                        <h3 className="text-xl font-bold text-heading uppercase">
                        {t('home-services-service-3')}
                        </h3>
                    </div>
                </div>
                <div className="flex flex-col mt-8 gap-4 items-center">
                    <h2 className="text-4xl font-extrabold text-heading">
                    {t('home-services-headline-3')}
                    </h2>
                    <Button href="/services"> {t('home-services-cta')}</Button>
                </div>
            </div>
            <div className="page__width flex flex-col-reverse gap-8 lg:gap-0 lg:flex-row items-center justify-between">
                <div className="w-full lg:w-1/2 flex flex-col gap-2">
                    <h2 className="text-4xl font-extrabold text-heading">
                    {t('home-about-headline')} <span className="text-brand">{t('home-about-headline-span')} </span>
                    </h2>
                    <p className="text-lg text-text">
                    {t('home-about-subheadline-1')} 
                    </p>
                    <p className="text-lg text-text">
                    {t('home-about-subheadline-2')}
                    </p>
                    <div className="items-center gap-4 flex">
                        <Icon
                            name="people"
                            className="w-16 h-16 m-0"
                        />
                        <h3 className="text-xl font-bold text-heading uppercase">
                        {t('home-about-label')}
                        </h3>
                    </div>
                </div>
                <ImageWrapper
                    className="w-full lg:w-2/5"
                    src={about}
                />
            </div>
            <div className="page__width my-20 flex flex-col-reverse gap-8 lg:gap-0 lg:flex-row items-center justify-between">
                <div className="w-full lg:w-1/2 flex flex-col gap-8">
                    <div className="flex gap-4 w-full items-start bg-white py-8 px-4 rounded-lg shadow-benefits">
                        <Icon
                            name="precision"
                            className="w-16 h-16"
                        />
                        <div className="flex w-full flex-col items-start gap-2">
                            <h3 className="text-2xl font-bold text-heading">
                            {t('home-why-1-headline')}
                            </h3>
                            <p className="text-base text-text"> {t('home-why-1-subheadline')}</p>
                        </div>
                    </div>
                    <div className="flex gap-4 w-full items-start bg-white py-8 px-4 rounded-lg shadow-benefits">
                        <Icon
                            name="rocket"
                            className="w-16 h-16"
                        />
                        <div className="flex w-full flex-col items-start gap-2">
                            <h3 className="text-2xl font-bold text-heading">
                            {t('home-why-2-headline')}
                            </h3>
                            <p className="text-base text-text"> {t('home-why-2-subheadline')}</p>
                        </div>
                    </div>
                    <div className="flex gap-4 w-full items-start bg-white py-8 px-4 rounded-lg shadow-benefits">
                        <Icon
                            name="money"
                            className="w-16 h-16"
                        />
                        <div className="flex w-full flex-col items-start gap-2">
                            <h3 className="text-2xl font-bold text-heading">
                            {t('home-why-3-headline')}
                            </h3>
                            <p className="text-base text-text"> {t('home-why-3-subheadline')} </p>
                        </div>
                    </div>
                </div>
                <div className="w-full lg:w-2/5 flex flex-col gap-2">
                    <h2 className="text-4xl font-extrabold text-heading">
                    {t('home-why-headline')} <span className="text-brand">{t('home-why-headline-span')}</span>
                    </h2>
                    <p className="text-lg text-text">{t('home-why-subheadline')}</p>
                </div>
            </div>
            <Testimonials />
            <div className="page__width flex flex-col lg:flex-row gap-8 lg:gap-0 items-start justify-between my-40">
                <div className="w-full lg:w-2/5 flex flex-col items-start gap-2 pr-8">
                <h3 className="text-xl lg:text-2xl font-bold text-heading">{t('contactform-headline')}</h3>
                <p className="w-full text-text text-base">{t('contactform-subheadline')}</p>
                    <div className="items-center gap-4 flex">
                        <Icon
                            name="email"
                            className="w-16 h-16 m-0"
                        />
                        <a
                            href="mailto:info@connectprint.net"
                            className="text-base font-bold text-heading uppercase">
                            info@connectprint.net
                        </a>
                    </div>
                    <div className="items-center gap-4 flex">
                        <Icon
                            name="phone"
                            className="w-16 h-16 m-0"
                        />
                        <a
                            href="tel:+359 876713508"
                            className="text-base font-bold text-heading uppercase">
                            +359 876713508
                        </a>
                    </div>
                </div>
                <div className="w-full lg:w-3/5 flex flex-col items-start">
                    <ContactForm />
                </div>
            </div>
        </>
    );
}

export default Home;
