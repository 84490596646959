import i18next from 'i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import Cookies from 'js-cookie';

const languageDetector = new LanguageDetector();
languageDetector.addDetector({
    name: 'cookie',
    lookup(options) {
        return Cookies.get('lang');
    },
});

i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // if you're using a language detector, do not define the lng option
        fallbackLng: 'de',
        debug: true,
        resources: {
            en: {
                translation: {
                    language: 'Language',
                    ['nav-home']: 'Home',
                    ['nav-services']: 'Services',
                    ['nav-contact']: 'Contact',
                    ['nav-cta']: 'Request a Quote',
                    ['nav-pp']: 'Request Privacy Policy',
                    ['nav-tnc']: 'Terms & Conditions Quote',
                    ['home-hero-headline']: 'Present Your Business',
                    ['home-hero-headline-span']: 'The Right Way',
                    ['home-hero-subheadline']:
                        'We are a printing company specialized in the production of books, magazines, catalogs, newspapers, and all types of promotional materials.',
                    ['home-hero-cta-primary']: 'Request a Quote',
                    ['home-hero-cta-secondary']: 'Our Services',
                    ['home-services-headline-1']: 'How',
                    ['home-services-headline-2']: 'makes you',
                    ['home-services-headline-span']: 'stand out?',
                    ['home-services-service-1']: 'Catalogs',
                    ['home-services-service-2']: 'Books',
                    ['home-services-service-3']: 'Magazines',
                    ['home-services-headline-3']: '...And Much More!',
                    ['home-services-cta']: 'See All Services',
                    ['home-about-headline']: 'Offset and ',
                    ['home-about-headline-span']: 'Digital Printing',
                    ['home-about-subheadline-1']:
                        'We offer offset and digital printing, along with a full range of finishing services.',
                    ['home-about-subheadline-2']:
                        'Our complete workflow ensures excellent quality and service at attractive prices for every customer.',
                    ['home-about-label']: '30+ Years of Experience',
                    ['home-why-headline']: 'Why',
                    ['home-why-headline-span']: 'ConnectPrint?',
                    ['home-why-subheadline']:
                        'We meet all technological requirements related to printing, packaging, and "door-to-door" delivery. The Connect Print team will provide you with the best possible printing service at any time.',
                    ['home-why-1-headline']: 'Quality',
                    ['home-why-2-headline']: 'Short period for order execution',
                    ['home-why-3-headline']: 'Competitive prices',
                    ['home-why-1-subheadline']:
                        'Connect Print team will do their best to provide you with the best possible service in the field of printing ',
                    ['home-why-2-subheadline']:
                        'Our team is always at your disposal to ensure that all your offset and digital printing requirements are met promptly',
                    ['home-why-3-subheadline']:
                        'We are primarily focused on delivering top-quality services to our partners at attractive prices.',
                    ['home-testimonials-1-comment']:
                        'The printing service was not only fast, but also of the highest precision - all details were implemented perfectly. I will definitely order here again.',
                    ['home-testimonials-1-name']: 'Moritz Müller',
                    ['home-testimonials-1-region']: 'Stuttgart',
                    ['home-testimonials-2-comment']:
                        'The printing service was not only fast, but also of the highest precision - all details were implemented perfectly. I will definitely order here again.',
                    ['home-testimonials-2-name']: 'Moritz Müller',
                    ['home-testimonials-2-region']: 'Stuttgart',
                    ['home-testimonials-3-comment']:
                        'The printing service was not only fast, but also of the highest precision - all details were implemented perfectly. I will definitely order here again.',
                    ['home-testimonials-3-name']: 'Moritz Müller',
                    ['home-testimonials-3-region']: 'Stuttgart',
                    ['contactform-headline']: 'Any Questions?',
                    ['contactform-subheadline']:
                        'Get in touch with us using the form or any of the following contact methods:',
                    ['contactform-contact-1']: 'info@connectprint.net',
                    ['contactform-contact-2']: '+359 876713508',
                    ['contactform-cta']: 'Send Message',
                    ['services-headline']: 'Our Services',
                    ['services-1-headline']: 'Books',
                    ['services-1-subheadline']:
                        'Whether you’re self-publishing or printing for a major release, we make sure your book looks as great as it reads. From crisp text to vibrant covers, we bring your story to life with quality you can see and feel',
                    ['services-1-cta']: 'Contact Us',
                    ['services-3-headline']: 'Hardcover',
                    ['services-3-subheadline']:
                        'For a polished, professional look that endures, our hardcover printing delivers. We craft durable, beautiful books that stand the test of time, perfect for making a lasting impression.',
                    ['services-3-cta']: 'Contact Us',
                    ['services-2-headline']: 'Softcover',
                    ['services-2-subheadline']:
                        'Looking for something sleek and easy to handle? Our softcover printing gives you high-quality results with a lighter touch, offering a perfect balance of affordability and style',
                    ['services-2-cta']: 'Contact Us',
                    ['services-4-headline']: 'Magazines',
                    ['services-4-subheadline']:
                        'We turn your ideas into stunning, page-turning magazines that grab attention and keep readers coming back for more. Bold visuals, sharp text – we’ve got your magazine covered, inside and out.',
                    ['services-4-cta']: 'Contact Us',
                    ['services-5-headline']: 'Catalogs',
                    ['services-5-subheadline']:
                        'Need to showcase your products in style? Our catalog printing makes sure every item shines, with crisp images and layouts that guide your customers effortlessly through your offerings.',
                    ['services-5-cta']: 'Contact Us',
                },
            },
            de: {
                translation: {
                    language: 'Sprache',
                    ['nav-home']: 'Home',
                    ['nav-services']: 'Dienstleistungen',
                    ['nav-contact']: 'Kontakt',
                    ['nav-cta']: 'Angebot anfordern',
                    ['nav-pp']: 'Datenschutzbestimmungen',
                    ['nav-tnc']: 'Bedingungen & Konditionen',
                    ['home-hero-headline']:
                        'Präsentieren Sie Ihr Unternehmen auf die ',
                    ['home-hero-headline-span']: 'richtige Weise',
                    ['home-hero-subheadline']:
                        'Wir sind eine Druckerei, die sich auf die Produktion von Büchern, Zeitschriften, Katalogen, Zeitungen und allen Arten von Werbematerialien spezialisiert hat.',
                    ['home-hero-cta-primary']: 'Angebot anfordern',
                    ['home-hero-cta-secondary']: 'Unsere Dienstleistungen',
                    ['home-services-headline-1']: 'Wie',
                    ['home-services-headline-2']: 'macht Sie',
                    ['home-services-headline-span']: 'hervorhebt',
                    ['home-services-service-1']: 'Kataloge',
                    ['home-services-service-2']: 'Bücher',
                    ['home-services-service-3']: 'Zeitschriften',
                    ['home-services-headline-3']: '...Und Vieles Mehr!',
                    ['home-services-cta']: 'Alle Dienste anzeigen',
                    ['home-about-headline']:
                        'Offset- and ',
                    ['home-about-headline-span']: 'Digitaldruck',
                    ['home-about-subheadline-1']:
                        'Wir bieten Offset- und Digitaldruck sowie ein komplettes Spektrum an Weiterverarbeitungsdiensten an.',
                    ['home-about-subheadline-2']:
                        'Unser vollständiger Arbeitsablauf gewährleistet hervorragende Qualität und Service zu attraktiven Preisen für jeden Kunden.',
                    ['home-about-label']: '30+ Jahre Erfahrung',
                    ['home-why-headline']: 'Warum',
                    ['home-why-headline-span']: 'ConnectPrint?',
                    ['home-why-subheadline']:
                        'Wir sind die Druckexperten, die Ihre Ideen schnell und kostengünstig in Perfektion umsetzen. Lehnen Sie sich zurück, entspannen Sie sich und lassen Sie uns Sie gut aussehen.',
                    ['home-why-1-headline']: 'Qualität',
                    ['home-why-2-headline']: 'Kurze Ausführungszeiten',
                    ['home-why-3-headline']: 'Wettbewerbsfähige Preise',
                    ['home-why-1-subheadline']:
                        'Das Connect Print-Team wird sein Bestes tun, um Ihnen den bestmöglichen Service im Bereich des Drucks zu bieten.',
                    ['home-why-2-subheadline']:
                        'Unser Team steht Ihnen jederzeit zur Verfügung, um sicherzustellen, dass all Ihre Anforderungen im Bereich des Offset- und Digitaldrucks in kurzer Zeit erfüllt werden.',
                    ['home-why-3-subheadline']:
                        'Wir konzentrieren uns in erster Linie darauf, unseren Partnern qualitativ hochwertige Dienstleistungen zu attraktiven Preisen anzubieten.',
                    ['home-testimonials-1-comment']:
                        'Der Druckservice war nicht nur schnell, sondern auch von höchster Präzision - alle Details wurden perfekt umgesetzt. Ich werde auf jeden Fall wieder hier bestellen.',
                    ['home-testimonials-1-comment']:
                        'Der Druckservice war nicht nur schnell, sondern auch von höchster Präzision - alle Details wurden perfekt umgesetzt. Ich werde auf jeden Fall wieder hier bestellen.',
                    ['home-testimonials-1-name']: 'Moritz Müller',
                    ['home-testimonials-1-region']: 'Stuttgart',
                    ['home-testimonials-2-comment']:
                        'Der Druckservice war nicht nur schnell, sondern auch von höchster Präzision - alle Details wurden perfekt umgesetzt. Ich werde auf jeden Fall wieder hier bestellen.',
                    ['home-testimonials-2-name']: 'Moritz Müller',
                    ['home-testimonials-2-region']: 'Stuttgart',
                    ['home-testimonials-3-comment']:
                        'Der Druckservice war nicht nur schnell, sondern auch von höchster Präzision - alle Details wurden perfekt umgesetzt. Ich werde auf jeden Fall wieder hier bestellen.',
                    ['home-testimonials-3-name']: 'Moritz Müller',
                    ['home-testimonials-3-region']: 'Stuttgart',
                    ['contactform-headline']: 'Haben Sie Fragen?',
                    ['contactform-subheadline']:
                        'Setzen Sie sich mit uns in Verbindung, indem Sie das Formular oder eine der folgenden Kontaktmethoden verwenden:',
                    ['contactform-contact-1']: 'info@connectprint.net',
                    ['contactform-contact-2']: '+359 876713508',
                    ['contactform-cta']: 'Nachricht senden',
                    ['services-headline']: 'Dienstleistungen',
                    ['services-1-headline']: 'Buchdruck',
                    ['services-1-subheadline']:
                        'Egal, ob Sie im Selbstverlag veröffentlichen oder für eine große Veröffentlichung drucken, wir sorgen dafür, dass Ihr Buch so gut aussieht, wie es sich liest. Vom gestochen scharfen Text bis zum lebendigen Einband erwecken wir Ihre Geschichte zum Leben - mit Qualität, die man sehen und fühlen kann..',
                    ['services-1-cta']: 'Kontaktieren Sie uns',
                    ['services-3-headline']: 'Harte Einbände',
                    ['services-3-subheadline']:
                        'Für ein ausgefeiltes, professionelles Erscheinungsbild, das Bestand hat, ist unser Hardcover-Druck ideal. Wir stellen langlebige, schöne Bücher her, die dem Test der Zeit standhalten und einen bleibenden Eindruck hinterlassen..',
                    ['services-3-cta']: 'Kontaktieren Sie uns',
                    ['services-2-headline']: 'Weiche Einbände',
                    ['services-2-subheadline']:
                        'Sie suchen nach einem eleganten und einfach zu handhabenden Format? Mit unserem Softcover-Druck erhalten Sie hochwertige Ergebnisse mit einem leichteren Touch, der eine perfekte Balance zwischen Erschwinglichkeit und Stil bietet..',
                    ['services-2-cta']: 'Kontaktieren Sie uns',
                    ['services-4-headline']: 'Zeitschriftendruck',
                    ['services-4-subheadline']:
                        'Wir verwandeln Ihre Ideen in atemberaubende, blätterbare Magazine, die die Aufmerksamkeit der Leser auf sich ziehen und sie dazu bringen, immer wieder zu kommen. Kühnes Bildmaterial, scharfer Text - wir haben Ihr Magazin im Griff, innen wie außen.',
                    ['services-4-cta']: 'Kontaktieren Sie uns',
                    ['services-5-headline']: 'Kataloge',
                    ['services-5-subheadline']:
                        'Sie müssen Ihre Produkte stilvoll präsentieren? Unser Katalogdruck sorgt dafür, dass jeder Artikel glänzt, mit gestochen scharfen Bildern und Layouts, die Ihre Kunden mühelos durch Ihr Angebot führen.',
                    ['services-5-cta']: 'Kontaktieren Sie uns',
                },
            },
            fr: {
                translation: {
                    language: 'Langue',
                    ['nav-home']: 'Accueil',
                    ['nav-services']: 'Services',
                    ['nav-contact']: 'Contact',
                    ['nav-cta']: 'Demande de devis',
                    ['nav-pp']: 'Request Privacy Policy',
                    ['nav-tnc']: 'Terms & Conditions Quote',
                    ['home-hero-headline']: 'Présentez votre entreprise de la ',
                    ['home-hero-headline-span']: 'bonne manière',
                    ['home-hero-subheadline']:
                        "Nous sommes une imprimerie spécialisée dans la production de livres, magazines, catalogues, journaux, et tous types de matériels promotionnels.",
                    ['home-hero-cta-primary']: 'Demande de devis',
                    ['home-hero-cta-secondary']: 'Nos services',
                    ['home-services-headline-1']: 'Comment',
                    ['home-services-headline-2']: 'vous permet-elle',
                    ['home-services-headline-span']: 'de vous démarquer?',
                    ['home-services-service-1']: 'Catalogues',
                    ['home-services-service-2']: 'Livres',
                    ['home-services-service-3']: 'Magazines',
                    ['home-services-headline-3']: '...et bien plus encore!',
                    ['home-services-cta']: 'Voir tous les services',
                    ['home-about-headline']:
                        'Impression offset ',
                    ['home-about-headline-span']: "et numérique",
                    ['home-about-subheadline-1']:
                        "Nous offrons des services d'impression offset et numérique ainsi qu'une gamme complète de services de finition.",
                    ['home-about-subheadline-2']:
                        "Notre flux de travail complet garantit une excellente qualité et un service à des prix attractifs pour chaque client.",
                    ['home-about-label']: "Plus de 30 ans d'expérience",
                    ['home-why-headline']: 'Pourquoi',
                    ['home-why-headline-span']: 'ConnectPrint?',
                    ['home-why-subheadline']:
                        'Nous répondons à toutes les exigences technologiques liées à l\'impression, l\'emballage, et la livraison « porte à porte ». L\'équipe de Connect Print vous fournira le meilleur service d\'impression possible à tout moment.',
                    ['home-why-1-headline']: 'Qualité',
                    ['home-why-2-headline']: 'Délai d\'exécution court',
                    ['home-why-3-headline']: 'Prix compétitifs',
                    ['home-why-1-subheadline']:
                        "L'équipe de Connect Print fera tout son possible pour vous offrir le meilleur service possible dans le domaine de l’impression.",
                    ['home-why-2-subheadline']:
                        "Notre équipe est toujours à votre disposition pour s'assurer que toutes vos exigences en matière d'impression offset et numérique soient satisfaites rapidement.",
                    ['home-why-3-subheadline']:
                        "Nous nous concentrons principalement sur la fourniture de services de haute qualité à nos partenaires à des prix attractifs.",
                    ['home-testimonials-1-comment']:
                        "Le service d'impression n'a pas seulement été rapide, il a aussi été d'une très grande précision - tous les détails ont été parfaitement mis en œuvre. Je ne manquerai pas de repasser commande chez nous.",
                    ['home-testimonials-1-name']: 'Moritz Müller',
                    ['home-testimonials-1-region']: 'Stuttgart',
                    ['home-testimonials-2-comment']:
                        "Le service d'impression n'a pas seulement été rapide, il a aussi été d'une très grande précision - tous les détails ont été parfaitement mis en œuvre. Je ne manquerai pas de repasser commande chez nous.",
                    ['home-testimonials-2-name']: 'Moritz Müller',
                    ['home-testimonials-2-region']: 'Stuttgart',
                    ['home-testimonials-3-comment']:
                        "Le service d'impression n'a pas seulement été rapide, il a aussi été d'une très grande précision - tous les détails ont été parfaitement mis en œuvre. Je ne manquerai pas de repasser commande chez nous.",
                    ['home-testimonials-3-name']: 'Moritz Müller',
                    ['home-testimonials-3-region']: 'Stuttgart',
                    ['contactform-headline']: 'Vous avez des questions ?',
                    ['contactform-subheadline']:
                        "Contactez-nous en utilisant le formulaire ou l'une des méthodes de contact suivantes :",
                    ['contactform-contact-1']: 'info@connectprint.net',
                    ['contactform-contact-2']: '+359 876713508',
                    ['contactform-cta']: 'envoyer un message',
                    ['services-headline']: 'Nos Services',
                    ['services-5-headline']: 'Impression de Catalogues',
                    ['services-5-subheadline']:
                        "Vous avez besoin de présenter vos produits avec style ? Notre service d'impression de catalogues s'assure que chaque article brille, avec des images et des mises en page nettes qui guident vos clients sans effort à travers vos offres",
                    ['services-5-cta']: 'Nous contacter',
                    ['services-2-headline']: 'Impression de Couverture souple',
                    ['services-2-subheadline']:
                        "Vous recherchez un produit élégant et facile à manipuler ? Notre impression de couvertures souples vous donne des résultats de haute qualité avec une touche plus légère, offrant un équilibre parfait entre l'abordabilité et le style.",
                    ['services-2-cta']: 'Nous contacter',
                    ['services-3-headline']: 'Impression de Couverture rigide',
                    ['services-3-subheadline']:
                        "Pour un aspect professionnel et soigné qui perdure, notre impression de couvertures rigides est une réussite. Nous fabriquons de beaux livres durables qui résistent à l'épreuve du temps, parfaits pour laisser une impression durable.",
                    ['services-3-cta']: 'Nous contacter',
                    ['services-1-headline']: 'Impression de Livres',
                    ['services-1-subheadline']:
                        "Qu'il s'agisse d'autoédition ou d'impression pour une sortie importante, nous veillons à ce que votre livre soit aussi beau qu'il se lit. Des textes nets aux couvertures éclatantes, nous donnons vie à votre histoire avec une qualité visible et palpable.",
                    ['services-1-cta']: 'Nous contacter',
                    ['services-4-headline']: 'Impression de Magazines',
                    ['services-4-subheadline']:
                        "Nous transformons vos idées en magazines époustouflants qui attirent l'attention et incitent les lecteurs à revenir. Visuels audacieux, textes percutants - nous nous occupons de votre magazine, à l'intérieur comme à l'extérieur.",
                    ['services-4-cta']: 'Nous contacter',
                },
            },
        },
    });
