import {ReactComponent as Burger} from "../icons/burger.svg";
import {ReactComponent as XIcon} from "../icons/x-icon.svg";
import {ReactComponent as CorporateEvents} from "../icons/corporate-events.svg";
import {ReactComponent as Wedding} from "../icons/wedding.svg";
import {ReactComponent as SocialGatherings} from "../icons/social-gatherings.svg";
import {ReactComponent as Gourmet} from "../icons/gourmet.svg";
import {ReactComponent as Cheers} from "../icons/cheers.svg";
import {ReactComponent as Bowl} from "../icons/bowl.svg";
import {ReactComponent as Paperplane} from "../icons/paperplane.svg";
import {ReactComponent as Arrow} from "../icons/arrow.svg";
import {ReactComponent as ShortArrow} from "../icons/arrow-short.svg";
import {ReactComponent as Instagram} from "../icons/instagram.svg";
import {ReactComponent as Email} from "../icons/email.svg";
import {ReactComponent as Money} from "../icons/money.svg";
import {ReactComponent as People} from "../icons/people.svg";
import {ReactComponent as Rocket} from "../icons/rocket.svg";
import {ReactComponent as Precision} from "../icons/precision.svg";
import {ReactComponent as Phone} from "../icons/phone.svg";
import {ReactComponent as Planet} from "../icons/planet.svg";

export default function Icon({name, ...props}){
    switch (name){
        case "burger":
            return <Burger {...props} />
        case "x-icon":
            return <XIcon {...props} />
        case "corporate-events":
            return <CorporateEvents {...props} />
        case "wedding":
            return <Wedding {...props} />
        case "social-gatherings":
            return <SocialGatherings {...props} />
        case "gourmet":
            return <Gourmet {...props} />
        case "cheers":
            return <Cheers {...props} />
        case "bowl":
            return <Bowl {...props} />
        case "paperplane":
            return <Paperplane {...props} />
        case "arrow":
            return <Arrow {...props} />
        case "arrow-short":
            return <ShortArrow {...props} />
        case "phone":
            return <Phone {...props} />
        case "precision":
            return <Precision {...props} />
        case "rocket":
            return <Rocket {...props} />
        case "people":
            return <People {...props} />
        case "money":
            return <Money {...props} />
        case "email":
            return <Email {...props} />
        case "instagram":
            return <Instagram {...props} />
        case "planet":
            return <Planet {...props} />
        default:
            return null;
    }
}