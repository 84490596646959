import React, { useState } from 'react';

function SidebarLink({ href, children, sublinks, scrollY }) {
    const [submenu, toggleSubmenu] = useState(false);
    return (
        <>
            {sublinks ? (
                <>
                    <button
                        className={`lg:hidden px-[7.5%] lg:p-0 w-full lg:w-auto hover:underline hover:bg-[#F1F1F1] lg:hover:no-underline lg:hover:bg-transparent`}
                        onClick={
                            sublinks ? () => toggleSubmenu(!submenu) : null
                        }>
                        <li
                            className={`lg:navlink flex items-center text-base lg:text-sm relative text-black py-4 lg:py-0 transition-all duration-200 ${
                                scrollY ? 'lg:text-black' : 'lg:text-white'
                            }`}>
                            {children}{' '}
                            {/* <Icon
                                name="chevron"
                                className={`${
                                    scrollY ? 'lg:text-black' : 'lg:text-white'
                                } text-black ml-2 w-3 h-3 ${
                                    submenu ? 'rotate-180' : 'rotate-0'
                                }`}
                            /> */}
                        </li>
                    </button>
                    <div className="relative group hidden lg:inline-block">
                        <button className={`text-sm navlink flex items-center ${scrollY ? 'lg:text-black' : 'lg:text-white'}`}>
                            {children}
                            {/* <Icon
                                name="chevron"
                                className={`${
                                    scrollY ? 'lg:text-black' : 'lg:text-white'
                                } text-black ml-2 w-2 h-2 group-hover:rotate-180`}
                            /> */}
                        </button>
                        <div className="group-hover:block border rounded-lg absolute hidden bg-white min-w-[350px] shadow-nav z-20">
                            {
                                sublinks?.map(link => {
                                    return <a
                                    href={link.href}
                                    className="flex items-center relative text-base text-black py-4 transition-all duration-200 pl-6 w-full hover:underline hover:bg-[#F1F1F1]">
                                    {link.label}
                                </a>
                                })
                            }
                        </div>
                    </div>
                </>
            ) : (
                <a
                    href={href}
                    className="flex items-center relative text-base text-black py-4 transition-all duration-200 pl-6 w-full hover:underline hover:bg-[#F1F1F1]">
                    {children}
                </a>
            )}

            <div
                className={`lg:hidden overflow-hidden flex flex-col w-full top-full left-0 z-[99] bg-white ${
                    submenu ? 'max-h-screen' : 'max-h-0'
                }`}>
                <ul className="pl-8 xl:pl-0">
                    {sublinks?.map((link) => {
                        return (
                            <SidebarLink
                                key={link.label}
                                dropdown={link.submenu}
                                href={link.href}>
                                {link.label}
                            </SidebarLink>
                        );
                    })}
                </ul>
            </div>
        </>
    );
}

export default SidebarLink;
