import React, { useState } from 'react'

export default function ImageWrapper({ object="fit" , children, ...props}) {
    const [loaded, setLoaded] = useState(false)

    return (
        <div {...props} style={{backgroundImage: `url(${props.blurImage})`, backgroundSize: "cover", backgroundPosition: "center"}}>
            <img onLoad={() => setLoaded(true)} loading='lazy' src={props.src} alt={props.alt} className={`w-full h-full object-${object} rounded-[inherit] ${loaded?'opacity-100':'opacity-0'}`} />
            {children}
        </div>
    )
} 