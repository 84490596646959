import React from 'react';
import Button from '../components/Button';

function NotFound() {
    return (
        <div className="page__width flex flex-col items-center justify-center text-center py-40">
            <h1 className="w-5/6 lg:w-3/4 text-heading font-bold text-5xl lg:text-7xl">Oops! Page Not Found</h1>
            <p className="text-xl text-text w-5/6 lg:w-1/2 my-4">We haven't printed this one out yet.</p>
            <Button href="/">Let's Get You Back Home</Button>
        </div>
    );
}

export default NotFound;
