import React from 'react';
import { Formik, Form } from 'formik';
import { sendContactForm } from '../utils/contactService';
import * as Yup from 'yup';
import Input from './Input';
import Button from './Button';
import { useTranslation } from 'react-i18next';

const schema = Yup.object().shape({
    name: Yup.string().required('Name is required.'),
    email: Yup.string()
        .email('Invalid email format.')
        .required('Email is required.'),
    phone: Yup.string().required('Phone Number is required.'),
});

function ContactForm({
    className = 'flex flex-col items-start w-full md:mx-auto',
    formName,
}) {
    const { t } = useTranslation();
    return (
        <div className="w-full">
            <Formik
                initialValues={{ name: '', phone: '', email: '', message: '' }}
                validationSchema={schema}
                onSubmit={(values, actions) => {
                    values.formName = formName;
                    sendContactForm(values)
                        .then(() => {
                            actions.resetForm();
                        })
                        .catch((err) => {})
                        .finally(() => actions.setSubmitting(false));
                }}>
                {({ isSubmitting, errors, touched }) => (
                    <Form
                        name={formName}
                        netlify="true"
                        data-netlify={true}
                        className={`${className} gap-2`}>
                        <Input
                            type="hidden"
                            name="bot-field"
                        />
                        <div className="flex flex-col gap-4 w-full lg:flex-row">
                            <div className="flex flex-col w-full lg:w-1/2 items-start">
                                <Input
                                    type="text"
                                    name="name"
                                    placeholder="Name"
                                    autocomplete="off"
                                />
                            </div>
                            <div className="flex flex-col w-full lg:w-1/2 items-start">
                                <Input
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                />
                            </div>
                        </div>
                        <Input
                            type="text"
                            name="phone"
                            placeholder="Phone"
                        />
                        <Input
                            type="text"
                            component="textarea"
                            name="message"
                            placeholder="Message"
                        />
                        <button
                            className={`group cursor-pointer text-base font-semibold flex items-center gap-2 py-3 px-8 bg-brand rounded-md mt-2`}
                            type="submit"
                            disabled={isSubmitting}>
                            {t('contactform-cta')}
                        </button>
                        {errors.name && touched.name ? (
                            <p
                                className="text-sm text-red-400 mt-1"
                                name="name"
                                component="div">
                                {errors.name}
                            </p>
                        ) : null}
                        {errors.email && touched.email ? (
                            <p
                                className="text-sm text-red-400 mt-1"
                                name="email"
                                component="div">
                                {errors.email}
                            </p>
                        ) : null}
                        {errors.phone && touched.phone ? (
                            <p
                                className="text-sm text-red-400 mt-1"
                                name="phone"
                                component="div">
                                {errors.phone}
                            </p>
                        ) : null}
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default ContactForm;
